import LanguageButton from '../components/LanguageButton';
import './Navbar.scss';

function Navbar({ handleLanguageChange, languageToChange }) {

    return (
        <nav className='navbar'>
            <LanguageButton handleLanguageChange={handleLanguageChange} languageToChange={languageToChange} />
        </nav>
    )
}

export default Navbar;