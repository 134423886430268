import LanguageButton from '../components/LanguageButton';

import './Header.scss';
import clock from '../assets/clock-outline.svg'
import downArrow from '../assets/down-arrow.svg'
import { useState, useEffect } from 'react';

function Header({
  headerTitle,
  headerSubTitle,
  headerDetails,
  headerReadingTime,
  headerBackground,
  backgroundPositionX,
  backgroundPositionY,
  language,
  primaryColor,
  lightColor
}) {

  const [actualLanguage, setActualLanguage] = useState(language);

  useEffect(() => {
    setActualLanguage(language);
  });

  return (
    <div className="hero-container">
      <div className='background-img' style={{ backgroundImage: 'url(' + headerBackground + ')', backgroundPositionX: backgroundPositionX, backgroundPositionY: backgroundPositionY }}></div>
      <header className='header' style={{ color: lightColor }}>
        <div className='header-title'>
          <h1>{headerTitle}</h1>
          <h3>{headerSubTitle}</h3>
        </div>
        <div className='header-details'>
          <h5>{headerDetails}</h5>
          <div className='reading-time'>
            <div className="clock-icon" style={{ maskImage: `url(${clock})`, WebkitMaskImage: `url(${clock})`, backgroundColor: lightColor }}></div>
            <h5>{headerReadingTime}{actualLanguage === "EN" ? " minutes" : " perc"}</h5>
          </div>
          <div className="down-arrow-icon" style={{ maskImage: `url(${downArrow})`, WebkitMaskImage: `url(${downArrow})`, backgroundColor: lightColor }}></div>
        </div>
      </header>
      <div className='colored-filter' style={{ backgroundColor: primaryColor }}></div>
    </div>
  );
}

export default Header;
