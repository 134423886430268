import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './SplitPost.scss';
import Post from './Post';

const SplitPost = () => {
  const { id1, id2 } = useParams();

  const [sliderValue, setSliderValue] = useState(50); // Default slider at 50%
  const containerRef = useRef(null);
  const leftPostRef = useRef(null);  // Reference to left post
  const rightPostRef = useRef(null); // Reference to right post

  const handleMouseMove = (e) => {
    if (!containerRef.current) return;

    const rect = containerRef.current.getBoundingClientRect();
    const offset = e.clientX - rect.left;
    const newValue = (offset / rect.width) * 100;

    if (newValue >= 0 && newValue <= 100) {
      setSliderValue(newValue);
    }
  };

  const handleMouseDown = () => {
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleMouseUp = () => {
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
  };

  // Sync the scroll positions of the two posts
  const syncScroll = (source, target) => {
    if (source && target) {
      target.scrollTop = source.scrollTop;
    }
  };

  useEffect(() => {
    const leftPost = leftPostRef.current;
    const rightPost = rightPostRef.current;

    if (leftPost && rightPost) {
      // Sync the scroll positions between both posts
      const handleLeftScroll = () => syncScroll(leftPost, rightPost);
      const handleRightScroll = () => syncScroll(rightPost, leftPost);

      leftPost.addEventListener("scroll", handleLeftScroll);
      rightPost.addEventListener("scroll", handleRightScroll);

      return () => {
        leftPost.removeEventListener("scroll", handleLeftScroll);
        rightPost.removeEventListener("scroll", handleRightScroll);
      };
    }
  }, []);

  return (
    <div className="comparison-container" ref={containerRef}>
      {/* Left Post */}
      <div
        className="post-container post-left"
        ref={leftPostRef}
        style={{
          clipPath: `polygon(0 0, ${sliderValue}% 0, ${sliderValue}% 100%, 0 100%)`,
        }}
      >
        <Post splittedId={id1} />
      </div>

      {/* Right Post */}
      <div
        className="post-container post-right"
        ref={rightPostRef}
        style={{
          clipPath: `polygon(${sliderValue}% 0, 100% 0, 100% 100%, ${sliderValue}% 100%)`,
        }}
      >
        <Post splittedId={id2} />
      </div>

      {/* Transparent Mask to allow interaction */}
      <div
        className="mask"
        style={{
          left: `${sliderValue}%`,
          width: `calc(100% - ${sliderValue}%)`,
        }}
      />

      {/* Slider */}
      <div
        className="slider"
        style={{ left: `${sliderValue}%` }}
        onMouseDown={handleMouseDown}
      ></div>
    </div>
  );
};

export default SplitPost;
