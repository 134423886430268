import { BrowserRouter, Route, Routes } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import Construction from "./pages/Construction";
import Post from "./pages/Post";
import SplitPost from "./pages/SplitPost";


function App() {
    return (
        <HelmetProvider>
            <Routes>
                <Route path="/" element={<Construction />}></Route>
                <Route path="/woof" element={<Construction />}></Route>
                <Route path="/woof/:id" element={<Post />}></Route>
                <Route path="/woof/split/:id1/:id2" element={<SplitPost />} />
            </Routes>
        </HelmetProvider>
    )
}

export default App